<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <div>Hello world hello world</div>
    <div>Hello world hello world</div>
    <div>Hello world hello world</div>
  </div>
</template>

<script lang="ts">
export default {
  name: "ExampleText",
};
</script>