
export default {
  props: ["data", "f"],
  computed: {
    d(): any {
      // @ts-ignore
      return this.$store.state.staticDataEditing ? this.data : this.f();
    },
  },
};
