
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  props: ["show"],
  data() {
    return { overlayText: "", overlayf: "" };
  },
  created() {
    // @ts-ignore
    this.overlayText = JSON.stringify(this.$store.state.overlayData);
    // @ts-ignore
    this.overlayf = this.$store.state.overlayf.toString();
  },
  computed: {
    vuexOverlayData(): any {
      // @ts-ignore
      return this.$store.state.overlayData;
    },
    vuexOverlayf(): any {
      // @ts-ignore
      return this.$store.state.overlayf;
    },
    staticDataEditing: {
      get(): boolean {
        // @ts-ignore
        return this.$store.state.staticDataEditing;
      },
      set(value: boolean) {
        // @ts-ignore
        this.$store.commit("updateEditingMode", {
          var: "staticDataEditing",
          newVal: value,
        });
        // this.$store.commit("updateInDesignMode", value);
      },
    },
  },
  watch: {
    overlayText: function (newVal: any) {
      // @ts-ignore
      this.$store.commit("updateComponentData", {
        // @ts-ignore
        id: this.$store.state.overlayId,
        // @ts-ignore
        compData: JSON.parse(newVal),
      });
    },
    vuexOverlayData(newVal: any) {
      // @ts-ignore
      this.overlayText = JSON.stringify(newVal) || "";
    },
    overlayf: function (newVal: any) {
      console.log("newVal");
      try {
        const payload = {
          // @ts-ignore
          id: this.$store.state.overlayId,
          // @ts-ignore
          compf: new Function("return " + newVal)(),
        };
        // @ts-ignore
        this.$store.commit("updateComponentf", payload);
      } catch (e) {
        console.log(e);
      }
    },
    vuexOverlayf(newVal: any) {
      // @ts-ignore
      this.overlayf = newVal.toString();
    },
  },
  //   setup() {
  //     const open = ref(true);

  //     return {
  //       open,
  //     };
  //   },
};
