
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { CubeIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    CubeIcon,
  },
};
