
import { defineComponent } from "vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import Overlay from "./Overlay.vue";
import Node from "./Node.vue";

export default defineComponent({
  name: "Home",
  components: {
    Node,
    Overlay,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  // data() {
  //   return { overlayOpen: false };
  // },
  computed: {
    inDesignMode: {
      get() {
        // @ts-ignore
        return this.$store.state.inDesignMode;
      },
      set(value) {
        // @ts-ignore
        this.$store.commit("updateEditingMode", {
          var: "inDesignMode",
          newVal: value,
        });
        // this.$store.commit("updateInDesignMode", value);
      },
    },
    allowTailwindEditing: {
      get() {
        // @ts-ignore
        return this.$store.state.allowTailwindEditing;
      },
      set(value) {
        // @ts-ignore
        this.$store.commit("updateEditingMode", {
          var: "allowTailwindEditing",
          newVal: value,
        });
      },
    },
    allowComponentAdding: {
      get() {
        // @ts-ignore
        return this.$store.state.allowComponentAdding;
      },
      set(value) {
        // @ts-ignore
        this.$store.commit("updateEditingMode", {
          var: "allowComponentAdding",
          newVal: value,
        });
      },
    },
    allowDataEditing: {
      get() {
        // @ts-ignore
        return this.$store.state.allowDataEditing;
      },
      set(value) {
        // @ts-ignore
        this.$store.commit("updateEditingMode", {
          var: "allowDataEditing",
          newVal: value,
        });
      },
    },
  },
  methods: {
    exportHtml: function () {
      // @ts-ignore
      const html = this.$store.state.tree.exportHtml();
      alert(html);
    },
    closeOverlay: function () {
      // @ts-ignore
      this.$store.commit("updateOverlay", { open: false, compData: null });
    },
  },
});
